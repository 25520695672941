// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";

// Amplify.configure(awsExports);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


const fs = require('fs');
const html = `<html lang="en">
<head>
    <meta charset="utf-8">
    <title>Redirecting...</title>
    <script>
        var redirectToApp = function() {
          window.location.replace("http://www.woopdo.com");
        };
        window.onload = redirectToApp;
    </script>
</head>
<body></body>
</html>`;

exports.handler = async (event) => {
    
    const response = {
        statusCode: 200,
        headers: {
            'Content-Type': 'text/html',
        },
        body: html,
    };
    return response;
};
